<template>
  <div>
    <el-row style="height: 95vh">
      <el-col :span="24" style="height: 100%">
        <el-card shadow="none" class="welcome">
          <h1 class="tit">
            欢迎来到Hikariの个人博客
            <div class="border"></div>
          </h1>

          <h2 class="intro">{{intro}}</h2>
          <div class="bounce down" @click="startRead">
            <i class="el-icon-arrow-down" style="color: white"></i>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-container id="index" class="animate__animated animate__fadeInUp">
      <el-row :gutter="12">
        <el-col :xs="24" :sm="17">
          <el-card style="background-color: rgba(255,255,255,0.9)" class="left-item">
            <div slot="header" class="total">
              <div class="title">
                <i v-if="selected" class="el-icon-back" @click="updateBlogList"></i>
                <span>{{selectMethod}}</span>
              </div>
              <span>共 <span style="color: #3a8ee6; font-size: 20px">{{totalcount}}</span> 篇</span>
            </div>
            <el-row type="flex" align="middle" style="flex-wrap: wrap" :gutter="20" v-for="blog in blogList"
                    :key="blog.id"
                    shadow="never" class="animate__animated animate__fadeInUp blog-content">
              <el-col class="img" :xs="24" :sm="8">
                <el-image lazy :src="blog.firstPicture"></el-image>
              </el-col>
              <el-col :xs="24" :sm="16">
                <div @click="getBlogInfo(blog.id)">
                  <h3>{{blog.title}}</h3>
                  <p class="description">{{blog.description}}</p>
                  <div class="blog-info">
                    <div class="user-info">
                      <el-avatar size="small" :src="blog.user.avatar"></el-avatar>
                      <a href="#" class="header">{{blog.user.nickname}}</a>
                    </div>
                    <div class="blog-date">
                      <i class="el-icon-date"></i>
                      <span>{{blog.createTime | dataFormat}}</span>
                    </div>
                    <div>
                      <i class="el-icon-view"></i>
                      <span>{{blog.views}}</span>
                    </div>
                    <div class="blog-type">
                      <el-tag effect="plain">{{blog.type.name}}</el-tag>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
          <el-pagination
                  :small="pagSmall"
                  background
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :page-size="queryInfo.pagesize"
                  :current-page="queryInfo.pagenum"
                  :layout="pagLayout"
                  :total="totalcount">
          </el-pagination>
        </el-col>
        <el-col :xs="24" :sm="7">
          <el-card style="background-color: rgba(255,255,255,0.9)"
                   class="animate__animated animate__fadeInUp right-item">
            <div slot="header" class="attributes">
              <b>分类</b>
            </div>
            <ul>
              <li class="animate__animated animate__fadeInUp blog-type" v-for="type in typeList"
                  :key="type.id"
                  @click="selectType(type.id)"
                  :class="type.id === typeId? 'activeType':''">
                <div style="display: flex;align-items: center">
                  <el-image lazy :src="type.pic_url"
                            style="width: 28px;height: 28px; border-radius: 50%; margin-right: 10px"></el-image>
                  {{type.name}}
                </div>
                <div>{{type.blogs.length}}</div>
              </li>
            </ul>
            <div class="more" @click="dealType">
              <i v-if="moreType" class="el-icon-arrow-down"></i>
              <i v-else class="el-icon-arrow-up"></i>
            </div>
          </el-card>
          <el-card style="background-color: rgba(255,255,255,0.9)"
                   class="animate__animated animate__fadeInUp right-item">
            <div slot="header" class="attributes">
              <b>标签</b>
            </div>
            <div class="tags">
              <div class="animate__animated animate__fadeInUp tag-item" v-for="tag in tagList"
                   :key="tag.id"
                   @click="selectTag(tag.id)"
                   :class="tag.id === tagId? 'activeTag':''">
                <div class="sjx-outer">
                  <div class="sjx-inner"></div>
                </div>
                <div class="tag">
                  {{tag.name}}
                  {{tag.blogs.length}}
                </div>
              </div>
            </div>
            <div class="more" @click="dealTag">
              <i v-if="moreTag" class="el-icon-arrow-down"></i>
              <i v-else class="el-icon-arrow-up"></i>
            </div>
          </el-card>
          <el-card style="background-color: rgba(255,255,255,0.9)"
                   class="animate__animated animate__fadeInUp right-item">
            <div slot="header" class="attributes">
              <span>最新推荐</span>
            </div>
            <div class="animate__animated animate__fadeInUp recommend-blog l-text" v-for="blog in recommendList"
                 :key="blog.id"
                 @click="getBlogInfo(blog.id)">
              <a>{{blog.title}}</a>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-container>
  </div>
</template>

<script>

export default {
  data() {
    return {
      totalcount: 100,
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 8
      },
      intro:'',
      blogList: [],
      typeList: [],
      tagList: [],
      recommendList: [],
      selectMethod: '全部博客',
      typeId: -1,
      tagId: -1,
      selected: false,
      moreType: true,
      moreTag: true,
      value: new Date(),
      timer: null,
      start: false,
      screenWidth: document.documentElement.clientWidth,  //实时屏幕宽度
    }
  },
  computed:{
    pagSmall(){
      return this.screenWidth <= 768;
    },
    // 计算分页栏样式
    pagLayout(){
      if (this.screenWidth<768){
        return 'prev, pager, next'
      } else {
        return 'total, prev, pager, next, jumper'
      }
    }
  },
  created() {
    window.addEventListener('resize', this.screenAdapter)
  },
  mounted() {
    this.getTypeList()
    this.getBlogList()
    this.getTagList()
    this.getRecommendList()
    let str = '这是我的个人博客、会分享关于编程，开发以及其他方面的一些内容，希望可以对您有所帮助...';
    let idx = 0;
    let that = this
    let timer = setTimeout( function fn() {
      // console.log(this.intro)
      that.intro = that.intro+ str.substring(idx,idx+1)
      idx++
      if (idx>str.length){
       that.intro = ''
        idx = 0
      }
      setTimeout(fn,200)
    },2000)

    this.screenWidth =  document.documentElement.clientWidth
  },
  methods: {
    // 开始进入主页
    startRead() {
      this.$nextTick(() => {
        document.getElementById('index').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          // inline: 'nearest'
        });
      })
    },
    compare(property) {
      return function (a, b) {
        let value1 = a[property].length;
        let value2 = b[property].length;
        return value2 - value1;
      }
    },
    // 获取推荐博客列表
    async getRecommendList() {
      const {data: res} = await this.$blog.get('/getRecommendBlogList')
      // console.log(res)
      this.recommendList = res.data
      this.total = res.total
    },
    // 获取博客类型列表
    async getTypeList() {
      const {data: res} = await this.$blog.get('/getTypeList')
      // console.log(res)
      this.typeList = res.data
    },
    // 获取博客标签列表
    async getTagList() {
      const {data: res} = await this.$blog.get('/getTagList')
      // console.log(res)
      this.tagList = res.data
    },
    // 获取博客列表
    async getBlogList() {
      const {data: res} = await this.$blog.get('/blogs', {
        params: this.queryInfo
      })
      // console.log(res)
      this.blogList = res.data.content
      this.totalcount = res.data.totalElements
    },
    // 跳转到博客详情页
    getBlogInfo(blogId) {
      // console.log(blogId.id)
      this.$router.push({path: '/blogInfo', query: {id: blogId}});
    },
    // 修改当前页码
    handleCurrentChange(newSize) {
      this.queryInfo.pagenum = newSize
      this.getBlogList()
    },
    // 修改当前页大小
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
    },
    // 按分类筛选博客
    async selectType(id) {
      this.typeId = id
      const {data: res} = await this.$blog.get(`/types/${this.typeId}`)
      // console.log(res)
      this.blogList = res.data.content
      this.totalcount = res.data.totalElements
      this.selectMethod = '分类: ' + this.typeList.find(item => item.id === this.typeId).name
      this.selected = true
    },
    // 按标签筛选博客
    async selectTag(id) {
      this.tagId = id
      const {data: res} = await this.$blog.get(`/tags/${this.tagId}`)
      this.blogList = res.data.content
      this.totalcount = res.data.totalElements
      this.selectMethod = '标签: ' + this.tagList.find(item => item.id === this.tagId).name
      this.selected = true
    },
    // 更新博客列表
    updateBlogList() {
      this.selected = false
      this.typeId = -1
      this.tagId = -1
      this.getBlogList()
    },
    // 得到所有的分类
    async getFullTypeList() {
      const {data: res} = await this.$blog.get('/getFullTypeList')
      this.typeList = res.data.sort(this.compare('blogs'))
    },
    // 得到所有的标签
    async getFullTagList() {
      const {data: res} = await this.$blog.get('/getFullTagList')
      // console.log(res.data)
      this.tagList = res.data.sort(this.compare('blogs'))
    },
    async dealType() {
      if (this.moreType) {
        await this.getFullTypeList()
      } else {
        await this.getTypeList()
      }
      this.moreType = !this.moreType
    },
    async dealTag() {
      if (this.moreTag) {
        await this.getFullTagList()
      } else {
        await this.getTagList()
      }
      this.moreTag = !this.moreTag
    },
    // 屏幕尺寸变化的监听函数
    screenAdapter() {
      this.screenWidth = document.documentElement.clientWidth;
    }

  },
}
</script>

<style scoped lang="less">

  body {
    width: 100%;
  }


  .welcome {
    background-color: rgba(0, 0, 0, 0.1);
    border: none;
    height: 90%;
    position: relative;

    .border {
      width: 812px;
      height: 112px;
      position: absolute;
      top: -6px;
      left: -6px;
      border: 3px solid white;
      box-sizing: border-box;
      animation: clipMe 5s linear infinite;
    }

    .tit {
      box-sizing: border-box;
      position: relative;
      width: 800px;
      height: 100px;
      line-height: 100px;
      box-shadow: inset 0 0 0 1px white;
      margin: 40px auto;
      margin-top: 80px;
      color: white;
      text-align: center;
      font-size: 50px;
      font-weight: normal;
      letter-spacing: 10px;
    }

    .intro {
      letter-spacing: 5px;
      line-height: 50px;
      width: 80%;
      margin: 0 auto;
      text-align: center;
      font-weight: normal;
      color: white;
    }

    .down {
      animation: bounce 2s infinite;
      animation-duration: 3s;
      font-size: 25px;
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid #fff;
    }

    .down:hover {
      animation: none;
      cursor: pointer;
      box-shadow: 0 0 20px 0 white;
      transition: all .2s;
    }
  }

  @keyframes clipMe {

    0%,
    100% {
      clip: rect(0px, 806px, 6px, 0px);
    }

    25% {
      clip: rect(0px, 6px, 112px, 0px);
    }

    50% {
      clip: rect(112px, 812px, 112px, 0px);
    }

    75% {
      clip: rect(0px, 812px, 112px, 806px);
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translate(-50%, 0);
    }
    40% {
      transform: translate(-50%, -30px);
    }
    60% {
      transform: translate(-50%, -15px);
    }
  }


  ul {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0;
    border-radius: 5px;
  }

  .el-pagination {
    padding-bottom: 20px;
  }


  .el-card /deep/ .el-card__body {
    padding: 0;
  }


  .right-item {
    margin-bottom: 20px;

    li:first-child {
      border-top: 1px solid rgba(179, 216, 255, 0.5);
    }

    li {
      border-bottom: 1px solid rgba(179, 216, 255, 0.5);
    }

    .more {
      text-align: center;
      color: #3a8ee6;
      padding: 8px;
    }

    .more:hover {
      cursor: pointer;
      color: #3a8ee6;
    }

    .blog-type:hover, .activeType {
      background-color: rgba(58, 142, 230, 0.3);
      cursor: pointer;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 15px 13px 0;
      border-bottom: 1px solid rgba(179, 216, 255, 0.5);

    }

    .tag-item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 10px;
      box-sizing: border-box;
      .tag {
        background-color: #ecf5ff;
        box-sizing: border-box;
        display: inline-block;
        height: 22px;
        padding: 0 10px;
        line-height: 22px;
        font-size: 10px;
        color: #409eff;
        border-radius: 4px;
        white-space: nowrap;
        border: 1px solid #409eff;
        transition: .2s;
      }

      .sjx-outer {
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid #409eff;
        position: relative;
        transition: .2s;
      }

      .sjx-inner {
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid #ecf5ff;
        top: -6px;
        left: 1px;
        position: absolute;
        transition: .2s;
      }
    }



    .tag-item:hover, .activeTag {
      box-sizing: border-box;
      .tag {
        color: white;
        background-color: #409eff;
        cursor: pointer;
      }

      .sjx-inner {
        border-right: 6px solid #409eff;
      }
    }

  }


  .blog-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 40px;
  }

  .recommend-blog {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding-left: 20px;
    padding-right: 20px;

    a {
      border-bottom: 1px solid rgba(34, 36, 38, .15);
      line-height: 40px;
      display: block;
      text-decoration: none;
      color: black;
    }

    a:hover {
      color: #3a8ee6;
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: larger;
    font-weight: bold;

    .title {
      display: flex;
      align-items: center;

      .el-icon-back {
        font-weight: bolder;
        color: #3a8ee6;
        margin-right: 10px;
      }

      .el-icon-back:hover {
        cursor: pointer;
      }
    }
  }

  .blog-content:hover {
    /*border-left: 5px solid #3a8ee6;*/
    /*border-right: 5px solid #3a8ee6;*/
    background-color: rgba(58, 142, 230, 0.3);
    cursor: pointer;

    .el-tag {
      color: white;
      background-color: #3a8ee6;
    }
  }

  .blog-content {
    padding: 10px;
    height: auto;
    border-bottom: 1px solid rgb(199, 163, 92);
    /*border-bottom: 1px solid rgba(34, 36, 38, .15);*/
    transition: .3s;


    .el-image {
      border-radius: 5px;
      box-sizing: border-box;
      flex-shrink: 0;
    }

    .blog-info {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, .4);
      font-size: 10px;

      .user-info {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-right: 15px;

        .el-avatar {
          margin-right: 5px;
          width: 22px;
          height: 22px;
        }

        .header {
          text-decoration: none;
          color: #3a8ee6;
          font-weight: bold;
        }
      }

      .blog-date {
        margin-right: 15px;
      }

      .blog-type {
        margin-left: auto;
      }
    }
  }

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font: 300 1em/1.8 PingFang SC, Lantinghei SC, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
  }

  @media screen and (max-width: 768px) {
    .blog-date {
      display: none;
    }

    .welcome {
      width: 100%;

      .border {
        display: none;
      }

      .tit {
        font-size: 2rem;
        width: 100%;
        line-height: 50px;
        letter-spacing: 2px;
        height: auto;
      }

      .intro {
        font-size: 1rem;
        line-height: 30px;
      }
    }

    .el-pagination {
      width: 100%;
    }
  }

</style>
